import React from "react"
import Consumer from "../layouts/Context"
import 'ssr-intersection-observer'
import { graphql } from "gatsby"
import { TransitionState } from "gatsby-plugin-transition-link"
import { InView } from 'react-intersection-observer'
// import "./css/video.scss"

// Components
import SEO from "../components/seo"
import ArticleWipe from "../components/ArticleWipe/ArticleWipe"
import ArticleHeader from "../components/ArticleHeader/ArticleHeader"
import ArticleHeaderBtn from "../components/ArticleHeaderBtn/ArticleHeaderBtn"
import ArticleShare from "../components/ArticleShare/ArticleShare"
import ArticleFooter from "../components/ArticleFooter/ArticleFooter"
import ArticleBio from "../components/ArticleBio/ArticleBio"
import ArticleBottom from "../components/ArticleBottom/ArticleBottom"

class Video extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      fixed: true
    }
  }

  componentDidMount() {
  }

  bottomInView = (inView, entry) => {
    this.setState({
      fixed: !inView
    })
  }

  contentInView = (inView, entry) => {
    // console.log(entry)
  }

  render() {
    const post = this.props.data.markdownRemark,
          seoDescription = post.frontmatter.seoDescription,
          seoImage = post.frontmatter.seoImage,
          title = post.frontmatter.title,
          subtitle = post.frontmatter.subtitle,
          author = post.frontmatter.author,
          authorAvatar = post.frontmatter.authorAvatar,
          authorBio = post.frontmatter.authorBio,
          htmlContent = post.html,
          youtubeCode = post.frontmatter.youtubeCode

    const vimeoUrl = `https://player.vimeo.com/video/${youtubeCode}`



    return (
      <TransitionState>
        {({ transitionStatus }) => {
          return (
            <>
              <SEO
                title={title}
                description={seoDescription ? seoDescription : post.excerpt}
                image={seoImage}
              />

              <Consumer>
                {context =>
                  <ArticleWipe context={context} />
                }
              </Consumer>

              <div className="page-wipe">
                <div className="page-wipe__panel page-wipe__panel--article" ref={pageWipe => (this.pageWipePanel = pageWipe)} />
              </div>

              <div className="article">
                <div className="article__inner">
                  <div className="container">

                    <ArticleHeaderBtn pageWipe={this.pageWipePanel} />
                    <ArticleHeader modifier="video" title={title} subtitle={subtitle} authorPrefix="by" author={author} />

                    <div className="cols flex">
                      <ArticleShare modifier="text" col={`m12 t1`} />
                      <div className="article__video-col col m12">
                        <div className="article__video">
                          <iframe
                            src={vimeoUrl}
                            width="1240"
                            height="900"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title={title}
                          />
                        </div>
                      </div>
                      <div className="col m12 t10 d8 dl6">
                        <div className="article__container">
                          <InView
                            onChange={this.contentInView}
                            threshold={0}
                          >
                            {({ inView, ref }) => (
                              <div
                                className="article__content"
                                dangerouslySetInnerHTML={{ __html: htmlContent }}
                                ref={ref}
                              />
                            )}
                          </InView>
                        </div>
                      </div>
                    </div>

                    <ArticleFooter fixed={this.state.fixed} />
                  </div>
                </div>

                { authorBio && authorAvatar && (
                  <ArticleBio author={author} authorAvatar={authorAvatar} authorBio={authorBio} />
                )}

                <InView
                  onChange={this.bottomInView}
                  threshold={0}
                >
                  {({ inView, ref }) => (
                    <div className="article__bottom" ref={ref} data-anim={inView}>
                      <ArticleBottom pageWipe={this.pageWipePanel} />
                    </div>
                  )}
                </InView>

              </div>
            </>
          )
        }}
      </TransitionState>
    )
  }
}

export default Video

export const pageQuery = graphql`
  query VideoBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        seoDescription
        seoImage
        chapter
        title
        subtitle
        author
        authorAvatar {
          publicURL
        }
        authorBio
        youtubeCode
      }
    }
  }
`